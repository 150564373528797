<template>
  <div v-if="isMounted">
    <big-title-color variant-class="clear" class="mb-base">
        <template v-slot:text>Aumenta tu <span>límite</span></template>.
    </big-title-color>
    <vx-card>
        <vs-alert icon-pack="feather" icon="icon-info" color="dark">
            <span>Una vez completados los pasos tu nivel se actualizará automáticamente y podrás verlo en la sección <strong>"Abonar Fondos"</strong>.</span>
        </vs-alert>
        <vs-tabs position="left">
            <vs-tab label="Domicilio" icon-pack="feather" icon="icon-home">
                <div class="tab-text">
                    <forms-container
                    :investor_id="InvestorId"
                    :investor_status="InvestorStatus"
                    :user_id="UserId"
                    :moral-tabs="moralTabs"
                    :pfae-tabs="pfaeTabs"
                    save-btn-id="confirm_address_limit_btn"
                    />
                </div>
            </vs-tab>
            <vs-tab label="Identificación oficial" icon-pack="feather" icon="icon-bookmark">
                <div class="tab-text">
                    <div class="vx-row" v-if="(mainComplianceStatus === 'running' && !investorHasOperations) && isMounted">
                    <div class="vx-col w-full h-auto">
                        <compliance-running />
                    </div>
                    </div>
                    <div class="vx-row" v-if="(mainComplianceStatus === 'completed' && !investorHasOperations) && isMounted">
                        <div class="vx-col w-full h-auto">
                            <fund-ready />
                        </div>
                    </div>
                    <vx-card v-if="(mainComplianceStatus === 'completed' && investorHasOperations) && isMounted">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                        <div class="mb-4">
                            <div class="flex flex-wrap items-center">
                            <p class="card-sub-title mr-5">
                                Verificación de identidad.
                            </p>
                            </div>
                        </div>
                        <p>
                            <span class="">El proceso de verificación de identidad <span class="bold text-primary">ha sido completado</span>.</span>
                        </p>
                        </div>
                    </div>
                    </vx-card>
                    <vx-card id="verifying_limit_info_container" v-if="(mainComplianceStatus === 'running' && investorHasOperations) && isMounted">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                        <div class="mb-4">
                            <div class="flex flex-wrap items-center">
                            <p class="card-sub-title mr-5">
                                Verificación de identidad.
                            </p>
                            </div>
                        </div>
                        <p>
                            <span class="bold">El proceso de verificación de identidad se encuentra en proceso.</span>
                            Vuelve en unos minutos para consultar si el proceso fue exitoso.
                        </p>
                        </div>
                    </div>
                    </vx-card>
                    <vx-card v-if="(mainComplianceStatus === 'pending') && isMounted">
                    <div class="vx-row">
                        <div class="vx-col w-full">
                        <div class="mb-4">
                            <div class="flex flex-wrap items-center">
                            <p class="card-sub-title mr-5" v-if="mverficationNoSuccessful && !processWasStarted">
                                La verificación de identidad no fue exitosa.
                            </p>
                            <p class="card-sub-title mr-5" v-else>
                                Verificación de identidad.
                            </p>
                            </div>
                        </div>

                        <template v-if="!processWasStarted">
                            <p v-if="mProcessIsPending">
                            En la Red, es importante verificar que tus datos concuerden con tu identidad.
                            Es por esto que utilizamos un software de reconocimiento de identidad a través de un proceso rápido y sencillo.
                            Para iniciar con el proceso da clic en “Comenzar” y después lee con calma las indicaciones que tenemos
                            para ti con el fin de que tu proceso sea exitoso.
                            </p>
                            <p v-if="mverficationNoSuccessful">
                            <span class="bold">El proceso de verificación de identidad no fue exitoso.</span> Comienza nuevamente siguiendo las recomendaciones
                            al pie de la letra y asegúrate de concluir el proceso correctamente. Principalmente cuida que tengas buena iluminación al momento de grabar
                            tu rostro o que las fotografías de tus documentos sean legibles, es decir, que no se vean borrosas o con reflejos que no nos permitan
                            leer la información claramente.
                            </p>
                        </template>
                        <template v-else>
                            <p>
                            En la Red, es importante verificar que tus datos concuerden con tu identidad.
                            Es por esto que utilizamos un software de reconocimiento de identidad a través de un proceso rápido y sencillo.
                            Para iniciar con el proceso da clic en “Comenzar” y después lee con calma las indicaciones que tenemos
                            para ti con el fin de que tu proceso sea exitoso.
                            </p>
                        </template>
                        </div>
                    </div>

                    <div class="vx-row mt-3" v-if="isMounted && processWasStarted" >
                        <div class="vx-col w-full">
                        <vs-alert color="warning">
                            <span class="bold">Estamos procesando tu información. Vuelve en unos minutos para consultar si el proceso fue exitoso.</span>
                        </vs-alert>
                        </div>
                    </div>

                    <div class="vx-row mt-3" v-if="isMounted && !processWasStarted" >
                        <!--div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsCompleted">
                        <vs-alert color="success">Tu identidad ha sido verificada correctamente.</vs-alert>
                        </div>
                        <div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsRejected">
                        <vs-alert color="warning">Lamentablemente no ha sido posible verificar tu identidad, por favor inténtalo de nuevo o contáctanos a través del chat.</vs-alert>
                        </div>
                        <div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsInProcess">
                        <vs-alert color="primary">La verificación de tu identidad se encuentra en proceso.</vs-alert>
                        </div>
                        <div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsReviewNeeded">
                        <vs-alert color="primary">La verificación de tu identidad se encuentra en proceso.</vs-alert>
                        </div>
                        <div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationisNewRequired">
                        <vs-alert color="primary">El proceso de verificación de identidad no fue exitoso, por favor inténtalo de nuevo.</vs-alert>
                        </div-->

                        <div class="vx-col w-full" v-if="mshouldRunMatiProcess">
                        <vs-button class="ml-auto mt-2" @click="openMatiPopup" v-if="mverficationNoSuccessful">Comenzar nuevamente</vs-button>
                        <vs-button class="ml-auto mt-2" @click="openMatiPopup" v-else>Comenzar</vs-button>
                        </div>
                    </div>
                    </vx-card>
                </div>
            </vs-tab>
        </vs-tabs>
    </vx-card>
    <mati-popup :user-id-to-activate="UserId" @on-mati-process-started="onMatiProcessStarted" />
  </div>
</template>

<script>
import MatiPopup from "@components/mati/MatiPopup";
import FormsContainer from "@components/profile_shared/FormsContainer";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import ComplianceRunning from "@components/compliance/ComplianceRunning";
import FundReady from "@components/compliance/FundReady";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import stpHelper from "@mixins/stpHelper";
export default {
  name: "IncrementInvestorContractLevel",
  components: {
    MatiPopup,
    FormsContainer,
    ComplianceRunning,
    FundReady
  },
  mixins: [userIdentityHelper, investorInfoHelper, stpHelper],
  data() {
    return {
      isMounted: false,
      identity: null,
      status: null,
      processWasStarted: false,
      pfaeTabs: ['address'],
      moralTabs: ['address'],
      identityIsCompleted: false,
      investorHasOperations: false,
      isMoral: null,
      mainComplianceStatus: null,
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.onUserInfoUpdated();
    await this.getIdentityInfo();
    await this.verifyCompliances();
    this.isMounted = true;
    this.showLoading(false);
  },
  computed: {
    mProcessIsPending(){
      return this.matiIsPending(this.status);
    },
    mshouldRunMatiProcess(){
      return this.shouldRunMatiProcess(this.status);
    },
    mverificationIsCompleted(){
      return this.verificationIsCompleted(this.status);
    },
    mverificationIsRejected(){
      return this.verificationIsRejected(this.status);
    },
    mverificationIsInProcess(){
      return this.verificationIsInProcess(this.status);
    },
    mverificationisNewRequired(){
      return this.verificationisNewRequired(this.status);
    },
    mverificationIsReviewNeeded(){
      return this.verificationIsReviewNeeded(this.status);
    },
    mverficationNoSuccessful(){
      return this.mverificationIsRejected || this.mverificationisNewRequired || this.mverificationIsReviewNeeded;
    }
  },
  methods: {
    async reloadStatus(){
      this.showLoading(true)
      await this.getIdentityInfo();
      this.processWasStarted = false;
      this.showLoading(false)
    },
    async verifyCompliances(){
      // si el inversionista ya ha hecho operaciones, tambien se le muestra el banner
      if(this.InvestorStatus > 2){
        this.investorHasOperations = true;
        //return;
      }

      // se verifica la identidad en general (ya sea p.fisica o p.moral)
      this.isMoral = this.userIsMoral(this.UserPersonType);
      let isForeign = this.userIsForeign(this.UserPersonType);

      // definir si mati esta pendiente o debe hacerse de nueva cuenta
      const shouldRunMati = this.shouldRunMatiProcess(this.status);
      if(shouldRunMati){
        this.mainComplianceStatus = "pending";
        return;
      }

      const matiIsRunning = this.matiIsRunning(this.status);
      if(matiIsRunning){
        this.mainComplianceStatus = "running";
        return;
      }

      // definir si esta completado
      const matiCompleted = this.verificationIsCompleted(this.status);
      if(this.isMoral || isForeign){
        if(matiCompleted){
          this.mainComplianceStatus = "completed";
        }
        else {
          this.mainComplianceStatus = "running";
        }
      }
      else {
        const stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
        if(matiCompleted && stpClabe !== null){
          this.mainComplianceStatus = "completed";
        }
        else {
          this.mainComplianceStatus = "running";
        }
      }
    },
    async onMatiProcessStarted(){
      this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
      this.processWasStarted = true;
    },
    async openMatiPopup(){
      this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
      this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
    },
    async getIdentityInfo(){
      this.status = await this.getUserIdentityStatus(this.UserId);
      this.identityIsCompleted = this.verificationIsCompleted(this.status);
    },
  }
}
</script>
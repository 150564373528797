<template>
  <vx-card card-background="#3b3a3d" title="¡Ya puedes abonar fondos!" title-color="white" >
    <p style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
      Tu cuenta en la Red está lista para que puedas abonar fondos y realizar ¡tu primera inversión de impacto en RedGirasol!
    </p>
  </vx-card>
</template>

<script>
export default {
  name: "FundReady"
}
</script>

<template>
  <vx-card card-background="#3b3a3d" title="Hemos recibido tu información." title-color="white" >
  <p style="color: white; opacity: 0.75;line-height: 1.5rem" class="text-md">
    Ya falta muy poco para que puedas abonar fondos y realizar ¡tu primera inversión de impacto en RedGirasol!
    Te pedimos estar atento a tu correo electrónico para recibir la confirmación de que tu cuenta ya está lista para operar.
    Este proceso puede tomar aproximadamente entre 5 y 10 minutos, y en algunos casos hasta 12 horas.
    Si tienes alguna duda sobre el proceso de verificación estamos para apoyarte
    a través del chat de la plataforma o en cualquiera de nuestras redes sociales.
  </p>
  </vx-card>
</template>

<script>
export default {
name: "ComplianceRunning"
}
</script>
